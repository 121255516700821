.container{
    display: flex;
    flex-direction: column;
    padding: 1rem 3.75rem;
    background:#fff;
}

.container tr{
    height:50px;
    -webkit-user-select: none;
     user-select: none;
     cursor: pointer;
}

.container  tbody tr, .container  nav {
    display:none;
 }
 .container  tbody tr:nth-child(1),
 .container  tbody tr:nth-child(2),
 .container  tbody tr:nth-child(3),
 .container  tbody tr:nth-child(4),
 .container  tbody tr:nth-child(5)  {
    display:table-row;
 }
.container  tbody td {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0.1rem;
    max-height: 44px;
    border-bottom: 0px;
}

.container  tbody td:first-child{
    padding: 0.8rem 0.3rem;
    justify-content: center;
    display: flex;
    align-items: center;
}


.container > header{
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
    padding:1rem 0.75rem;
    align-items: center;
    background:#EEEAF5;
    margin-bottom: 0.8em;
}


.container > header h3{
    margin: 0.5rem 0rem;
}
.linkbutton{    font-size: 0.72rem;
    color: #765EB5;
    font-weight:bold;
}
.container thead{  display: none; }
.boxinfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    font-size: 0.875rem; 
    margin: 0.24rem;
}
.container >  .linkbutton{
    text-decoration: none;
    color: #765EB5;
    margin-top: 1rem;
    padding-left: 0.9rem;
}
.container >  .linkbutton:hover{
    text-decoration: underline;    
}

