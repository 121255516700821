html {
  font-size: 16px;
}

// input[class*="MuiInputBase"]:-internal-autofill-selected {
//   appearance: initial;
//   box-shadow: 0px -7px 1px #e8f0fe !important;
//   background-color: #f0f !important;
//   background: #f0f !important;
//   background-image: none !important;
//   color: #f0f !important;
// }
// input:focus {
//   background-position: 0 0;
// }
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 4rem white inset !important;
    box-shadow: 0 0 0 4rem white inset !important;
}

.makeStyles-Baloon-11, .makeStyles-Baloon-15{
  visibility: hidden;
}

.defContainer {
  width: 100%;
  padding: 1rem 1.5rem;
  font-family: 'Fira Sans', sans-serif;
  background:#f4f6f9; 
  min-height: calc(100vh - 170px);
}

.defContainerGrey {
  @extend .defContainer;
  background: #F2F3F7;
  min-height: calc(100vh - 330px);
  box-sizing: border-box;
}

.defWrapperContainer {
  display: flex;
  padding: 0.5rem;
  overflow:hidden;
}

.defWrapperContainer_3 { 
  @extend .defWrapperContainer 
} 

.defWrapperContainer_3 > .Wrapper {
  flex-basis: 32.66%;
  margin-right: 1%;
} 
.defWrapperContainer_3 > .Wrapper:last-child{  margin-right: 0%; } 

.defSidepanel{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding:1rem;
}

button[disabled], button[disabled]:hover, .onlyRead, .onlyRead:hover{
  color: #999;
  cursor: default !important;
  opacity: 0.8 !important;
}
button[disabled]:hover *{
  cursor: default;
  opacity: 0.8;
}
// tbody tr:hover{  opacity:0.9; }
// tbody tr:hover td{  background: #EEEAF5 !important; }
tbody tr:hover{  opacity:0.85; }
tbody tr:hover td *{  color: #765EB5 ; }
tbody tr:hover td button, tbody tr:hover td button *{  color: #ffffff ; }

.space {  flex-grow: 1; }

.msgerror{
  padding: 1rem;
  color: #CC0000;
  width: 100%;
  text-align: center;
}

.MuiTouchRipple-root {
  display: none;
}

.MuiCardActionArea-focusHighlight {
  display: none;
}
.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.75);
}

/* TABELLE */
th .MuiFormControlLabel-label{  font-weight: 600; }
.MuiTableCell-sizeSmall {  padding: 6px 5px 6px 8px; }

.Mui-disabled.MuiInputBase-root, .Mui-disabled {
  background-color: #e7e7e7 !important;
  color: #6D6D6D;
  cursor: default;
 }
 .Mui-disabled.MuiInputBase-root input{  box-shadow:0 0 0 4rem #e7e7e7 inset !important;  }

.MuiFormHelperText-root.Mui-disabled {
  background-color:transparent !important;
}
.header_username{ cursor: pointer;  }
.header_username:hover{ text-decoration: underline; }

.disabled .MuiInputBase-root {
  opacity: 0.6 !important;
  background-color: #fff !important;
  color: #666;
}
.none {
  display: none !important;
}
.pointer{ cursor: pointer;  }
.clickable{ cursor: pointer;  }
.clickable:hover{ opacity:0.85;  }
.hoverable:hover{ opacity:0.85;  } 
.bold{ font-weight:bold; }


::i-block-chrome,
.dex-mi-btn {
  display: -webkit-box;
  align-self: baseline;
  top: 15px;
}




@media (max-width: 1280px) {  
  .defContainer {
    padding: 1rem 0.6rem;
  }
}
